<template>
    <component
        :errors="combinedErrors"
        :disabled="disabled"
        :readonly="readonly"
        @input="updateLocal"
        :value="local"
        :item="item"
        v-bind="$attrs"
        :is="uploadComponent"
        ref="manager"
    />
</template>

<script>
import ImageUploadSingle from "@Platon/components/form/controls/ImageUploadSingle.vue"
import ImageUploadMultiple from "@Platon/components/form/controls/ImageUploadMultiple.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import UploadUtilsMixin from "@Platon/components/form/controls/UploadUtilsMixin"

export default {
    name: "ImageControl",

    components: { ImageUploadSingle, ImageUploadMultiple },
    mixins: [InputControlMixin, ValidationMixin, UploadUtilsMixin],

    computed: {
        uploadComponent() {
            if (this.item.isMultiple) {
                return "image-upload-multiple"
            } else {
                return "image-upload-single"
            }
        }
    }
}
</script>

<style></style>
