<template>
    <div class="image-m-item cursor-pointer" :style="{ width: itemWidth }" ref="container">
        <div
            class="image-m-preview btn btn-light align-items-center justify-content-center position-relative d-flex"
            @click="$emit('pick')"
        >
            <div class="position-absolute">
                <i class="fas fa-camera-retro" style="font-size: 2.5rem"></i>
                <div>{{ $l("platon.upload_add_photo", "Расм қўшиш") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImageUploadMultipleAdd",

    props: {
        itemWidth: {
            type: String,
            default: "150px"
        }
    }
}
</script>
