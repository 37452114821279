<template>
    <div class="image-m-item" :style="{ width: this.itemWidth }" ref="container">
        <div class="image-m-container">
            <div
                class="image-m-preview"
                ref="image"
                :style="{ 'background-image': `url('${this.remoteFileUrl}')` }"
                @click="$emit('preview')"
                style="cursor: zoom-in"
            ></div>

            <div v-if="isUploading" class="position-absolute badge badge-primary" style="bottom: 50px; right: 10px">
                {{ uploadProgress }}%
            </div>

            <div class="position-absolute info p-1" style="bottom: 0; width: 100%">
                <div class="file-name d-flex">
                    <div class="d-inline-block text-truncate overflow-hidden flex-grow-1">{{ fileName }}</div>
                    <div
                        class="ml-1 cursor-pointer"
                        v-if="!isUploading && !isUploadError && canDownload"
                        @click="downloadRemoteFile"
                    >
                        <i class="fa fa-download"></i>
                    </div>
                    <div
                        class="ml-1 cursor-pointer"
                        v-if="!isUploading && !isUploadError && !uploadControl.isDisabledOrReadOnly"
                        @click="deleteUploadedFile"
                    >
                        <i class="fa fa-trash text-danger"></i>
                    </div>
                    <div class="ml-1 cursor-pointer" v-if="isUploadError" @click="uploadFile()">
                        <i class="fa fa-redo"></i>
                    </div>
                    <div class="ml-1 cursor-pointer d-flex align-items-center" v-if="isUploading">
                        <b-spinner variant="primary" style="width: 10px; height: 10px; border-width: 0.12em" />
                    </div>
                    <div class="ml-1 cursor-pointer" v-if="isUploading || isUploadError" @click="cancelUpload">
                        <i class="fa fa-times text-danger"></i>
                    </div>
                </div>
                <div class="uploaded-date">{{ fileDescription }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import UploadMixin from "@Platon/components/form/controls/UploadMixin"
import ImageUploadMixin from "@Platon/components/form/controls/ImageUploadMixin"

/**
 * @property {PlatonFormElement} uploadControl
 */
export default {
    name: "ImageUploadMultipleItem",

    mixins: [UploadMixin, ImageUploadMixin],

    props: {
        info: {},

        itemWidth: {
            type: String,
            default: "150px"
        }
    },

    inject: ["item", "uploadControl"],

    mounted() {
        if (this.info && this.info.file) {
            this.onFileSelected(this.info.file)
        }
    }
}
</script>
